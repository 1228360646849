.lcontain {
	display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

	&__form {
		border: rgb(29 108 35 / 65%) solid 1px;
		padding: 24px;
		border-radius: 8px;
		background: rgb(29 108 35 / 2%);
		max-width: 600px;
    	min-width: 400px;
	}

	&__form-row {
		font-weight: bold;
    	letter-spacing: 0.5px;
	}
}
@primary-color: #009688;