@first-break: 700px;
@first-break-upper: 701px;

.menu-con {
	width: 100%;
	@media screen and (max-width: @first-break) {
		justify-content: end;
	}
}

.barIcon {
	display: none;
	@media screen and (max-width: @first-break) {
		display: flex;
		justify-content: end;
	}
}

.desktop-menu {
	margin-left: 24px;
	padding-left: 24px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-evenly;
	max-width: 350px;
	display: none;
	@media screen and (min-width: @first-break-upper) {
		display: flex;
	}
}

.dropdown-meanu {
	background-color: #009688;
    padding: 4px 8px;
    min-width: 100px;
    border-radius: 4px;
}

.icon-common {
	color: white;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}
@primary-color: #009688;