.order-item {
	border: 1px solid;
    border-radius: 4px;
    min-width: 500px;
    box-shadow: rgb(0 150 136 / 98%) 2px 2px 4px 4px;
    background-color: rgb(0 150 136 / 8%);
}

.order-title {
	font-size: 16px;
    font-weight: 600;
}

.order-date {
	position: absolute;
    top: -10px;
    background: #e0ebed;
    padding: 0px 5px;
    border-radius: 2px;
}

.order-details-btn {
	position: absolute;
    right: 0;
    padding: 8px;
    bottom: 0px;
    background-color: #009688;
    color: white;
    border-radius: 4px 0px 0px 0px;
    cursor: pointer;
	width: 100px;
}

.order-cancel-btn {
	position: absolute;
    right: 0;
    padding: 8px;
    top: 0px;
    background-color: rgb(244 67 54 / 75%);
    color: white;
    border-radius: 0px 0px 0px 4px;
    cursor: pointer;
	width: 100px;
}

.order-other-status {
    position: absolute;
    top: -10px;
    right: 4px;
    background: #e0ebed;
    padding: 0 4px;
    border-radius: 2px;
}
@primary-color: #009688;