.slhome {
	height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
	overflow: scroll;

	&-intro {
		font-size: 40px;
		color: #009688;
		font-weight: bold;
	}

	&-contact {
		font-size: 40px;
		color: #ff5722;
		font-weight: bold;
	}
}

.slhome-content {
	display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
	padding-top: 16px;
}

.slhome-spin {
	justify-content: center;
    display: flex;
    padding-top: 16px;
}
@primary-color: #009688;