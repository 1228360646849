@first-break-footer: 550px;
@first-break-footer-upper: 551px;

.slphone {
	&::selection {
		background: black;
		color: white;
	}
}

.sl-copy {
	display: none;
	@media screen and (min-width: @first-break-footer-upper) {
		display: block;
	}
}

.sl-copy-small {
	display: none;
	@media screen and (max-width:@first-break-footer) {
		display: block;
	}
}

@primary-color: #009688;